* {
  font-family: 'Exo', sans-serif;
}

h3 {
  font-size: 24px;
}

button {
  background: #ED672C;
  border-radius: 5px;
  padding: 10px 20px;
  border: none;
  font-weight: 700;
  color: white;
  cursor: pointer;
}

button.secondary {
  color: #ED672C;
  background: white;
  font-weight: normal;
}

button.secondary:hover {
  background: rgba(0,0,0,0.15)
}

button.text {
  color: black;
  background: transparent;
  font-weight: normal;
}

.root {
  text-align: center;
}

.root-container {
  width: 90%;
  max-width: 1240px;
  display: inline-block;
}

a {
  cursor: pointer;
}

input[type=text] {
  background: #FFFFFF;
  border: 1px solid #F0EFEF;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.15);
  min-height: 35px;
  padding-left: 5px
}

.no-outline:focus {
  outline: none;
}

.tag-hover {
  background: rgba(237, 103, 44, 0.075) !important
}

.tag-hover:hover {
  background: rgba(237, 103, 44, 0.15) !important
}

.tag-hover-selected {
  background: rgba(237, 103, 44, 0.15) !important
}

.tag-hover-selected:hover {
  background: rgba(237, 103, 44, 0.11) !important
}

.gray-hover:hover {
  background: rgba(237, 103, 44, 0.07) !important
}

textarea {
  background: #FFFFFF;
  border: 1px solid #F0EFEF;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.15);
  height: 35px;
  padding: 7px;
}

.qa-card {
  background: #FFFFFF;
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: calc(100% - 40px);
  padding-right: 10px;
  padding-top: 10px;
  padding-left: 15px;
  padding-bottom: 15px;
}

@media screen and (max-width: 1080px) {
  .auth-info-container .avatar {
    width: 25px !important;
    height: 25px !important
  }

  .top-bar {
    flex-wrap: wrap;
  }

  .search-bar {
    flex-basis: 100%;
  }

  .search-bar input {
    width: 100%;
    margin-top: 20px;
  }

  .qa-card .right-column {
    max-width: 100px;
  }

  .qa-card .left-column {
    min-width: 100px !important;
  }

  .pagination {
    flex-direction: column;
    align-items: flex-start !important;
    height: 100px
  }
}

@media screen and (max-width: 800px) {
  .center-column {
    width: unset !important;
    margin-right: 10px;
    font-size: 12px !important;
  }

  .right-column {
    align-self: flex-end;
  }

  .right-column * {
    font-size: 12px !important;
  }

  .left-column {
    font-size: 12px !important;
  }

  .auth-info-container {
    flex-basis: 100%;
    margin-top: 10px;
    justify-content: flex-end;
  }

  .qa-card .avatar {
    width: 25px !important;
    height: 25px !important;
    top: 0 !important;
  }

  /* .qa-card .avatar.asker {
    top: 20px !important;
  } */

  .qa-card .avatar + div {
    display: block !important
  }

  .root-container {
    width: calc(100% - 30px)
  }

  .qa-card .container {
    flex-direction: column-reverse;
  }

  .qa-card .container > div:first-child {
    max-width: 100% !important
  }
}

@media screen and (max-width: 500px) {
  .center-column {
    font-size: 11px !important;
  }

  .right-column * {
    font-size: 11px !important;
  }

  .left-column {
    font-size: 11px !important;
    min-width: 70px !important;
  }

  .pagination * {
    font-size: 11px !important;
  }

  button.text {
    padding: 5px 6px !important;
  }
}